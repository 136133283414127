<template>
  <section
    document-invoice-attachment-modal
    :style="{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingBottom: '20px'
    }"
  >
    <div v-if="base64pdf" class="has-text-centered">
      <div>
        <div>
          <div v-dragscroll="true" :class="['doc-viewer', { 'zoom-active': scale > 0.9 }]">
            <pdf
              style="display: none;"
              :src="base64pdf"
              @num-pages="pageCount = new Array($event)"
            />
            <!-- Actual document -->
            <pdf
              v-for="(page, index) in pageCount"
              :key="index"
              :src="`${base64pdf}`"
              :page="index + 1"
              :scale="scale"
              :resize="true"
              :style="{ margin: 'auto', width: `${100 * scale}%` }"
            />
          </div>
        </div>
        <div class="doc-controls has-text-center">
          <b-field position="is-centered">
            <div class="control">
              <b-button @click="zoom(-1)">-</b-button>
            </div>
            <div class="control">
              <b-button @click="zoom(1)">+</b-button>
            </div>
          </b-field>
        </div>
      </div>
    </div>

    <div v-else-if="imageContent">
      <img :src="imageContent" />
    </div>

    <!-- Show the Upload Form -->
    <div v-if="!attachment" class="file">
      <b-upload v-model="file" drag-drop accept="application/pdf, image/png, image/jpeg, image/jpg">
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="file" size="is-large"> </b-icon>
            </p>

            <p v-if="file">
              {{ file.name }}
            </p>
            <p v-else>
              Drop a PDF, PNG, JPG or JPEG file here, or click to select a file from your computer.
            </p>
          </div>
        </section>
      </b-upload>
    </div>

    <div v-if="!attachment" :style="{ overflow: 'hidden', marginTop: '24px' }">
      <b-button type="is-primary" :disabled="!file" @click="submitFile" :style="{ float: 'right' }"
        >Upload</b-button
      >
    </div>

    <div v-if="attachment" :style="{ overflow: 'hidden', marginTop: '24px' }">
      <b-button type="is-primary" @click="closeModal" :style="{ float: 'right' }">Close</b-button>
    </div>
  </section>
</template>

<script>
/*
		import DocumentInvoiceAttachmentModal from '@/components/Modals/DocumentInvoiceAttachmentModal"
*/

// Components
import kms from '@/services/kms'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
import { dragscroll } from 'vue-dragscroll'
import pdf from 'vue-pdf'
import moment from 'moment'
import $ from 'jquery'
import { notifyError, notifyMessage } from '@/services/notify'

export default {
  name: 'DocumentInvoiceAttachmentModal',
  components: {
    pdf
  },

  directives: { dragscroll },

  props: {
    apInvoiceID: Number,
    attachment: Object
  },

  data: function() {
    return {
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: false,
      file: null,
      base64pdf: '',
      scale: 1,
      searchTerm: -1,
      imageContent: null,
      pageCount: []
    }
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState('accounting', ['uploadedInvoiceAttachmentFile']),
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    reloadDocuments() {
      this.reload()
    }
  },

  methods: {
    ...mapActions('accounting', ['uploadApInvoiceAttachmentDocument']),
    ...mapActions('accounting', ['updateInvoiceAttachment']),

    reload() {
      if (
        this.isDebug == true &&
        this.attachment &&
        this.attachment.apInvoiceAttachmentID != undefined
      )
        console.debug('reload attachment=' + this.attachment.apInvoiceAttachmentID)

      if (
        this.attachment &&
        this.attachment.apInvoiceAttachmentID &&
        this.attachment.apInvoiceAttachmentID > 0
      ) {
        this.$store
          .dispatch('accounting/getApInvoiceAttachmentById', {
            apInvoiceAttachmentID: this.attachment.apInvoiceAttachmentID
          })
          .then(() => {
            if (this.attachment && this.attachment.documentID && this.attachment.documentID > 0) {
              if (this.isDebug == true)
                console.debug('attachment.documentID=' + this.attachment.documentID)
              this.loadCurrentDocument()
            }
          })
      }
    },

    async loadCurrentDocument() {
      if (
        !this.attachment ||
        this.attachment == undefined ||
        !this.attachment.apInvoiceAttachmentID ||
        this.attachment.apInvoiceAttachmentID == undefined
      ) {
        return
      }

      this.searchTerm = this.attachment.name.indexOf('.pdf')

      try {
        const params = {
          invoiceAttachmentID: this.attachment.apInvoiceAttachmentID,
          asBase64: true
        }

        const path = `/AccountsPayable/APInvoiceAttachments/GetAttachmentFile`
        const returned = await kms.get(path, {
          params
        })

        if (this.isDebug == true)
          console.debug(
            'path base64 path=' +
              path +
              ' - params' +
              JSON.stringify(params) +
              ' - returned=' +
              JSON.stringify(returned)
          )
        if (returned && returned != undefined) {
          if (this.isDebug == true)
            console.debug(
              'image or pdf............this.searchTerm:' +
                this.searchTerm +
                '............' +
                returned
            )

          if (this.searchTerm != -1) {
            this.base64pdf = returned
            this.imageContent = null
          } else {
            this.imageContent = returned
            this.base64pdf = null
          }
        }
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
      }
    },

    closeModal() {
      this.$emit('update:toggle', (this.invoiceAttachmentToggle = !this.invoiceAttachmentToggle))

      // auto-close modal
      $('#clickclose').click()
    },
    async removeFile() {
      if (this.attachment.apInvoiceAttachmentID && this.attachment != undefined) {
        try {
          const payload = {
            apInvoiceAttachmentID: this.attachment.apInvoiceAttachmentID,
            documentId: null,
            apInvoiceID: this.attachment.apInvoiceID,
            name: this.attachment.name,
            createdDate: this.attachment.createdDate,
            modifiedDate: this.attachment.modifiedDate,
            deletedDate: this.attachment.deletedDate
          }

          const path = '/AccountsPayable/APInvoiceAttachment'

          const results = await kms.put(path, payload)
          this.base64pdf = ''
          if (results && results != undefined) {
            if (this.isDebug == true)
              console.debug('results.............................=' + JSON.stringify(results))

            notifyMessage(`Successfully removed the document.`)
          } else {
            notifyError('There was a problem removing this document.')
          }
        } catch (e) {
          notifyError(e)
        }
        this.loading = false
      } else {
        notifyError('There was a problem removing this document.')
      }
    },
    async submitFile() {
      if (this.apInvoiceID <= 0) {
        notifyError('There was a problem uploading this document.')
        return
      }

      try {
        if (!this.attachment) {
          const payload = {
            file: this.file,
            apInvoiceID: this.apInvoiceID
          }

          const results = this.uploadApInvoiceAttachmentDocument(payload).then(() => {
            this.base64pdf = ''
            if (results && results != undefined) {
              if (this.isDebug == true)
                console.debug('results.............................=' + JSON.stringify(results))

              this.imageContent = null
              this.base64pdf = null

              setTimeout(() => {
                this.$emit(
                  'update:toggle',
                  (this.invoiceAttachmentToggle = !this.invoiceAttachmentToggle)
                )

                // auto-close modal
                $('#clickclose').click()
              }, 2000)

              notifyMessage(`Successfully uploaded the document.`)
            } else {
              notifyError('There was a problem uploading this document.')
            }
          })
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    },

    zoom(direction) {
      const amt = Math.sqrt(2)
      if (direction > 0) {
        this.scale *= amt
      } else {
        this.scale /= amt
      }

      // constrain to min/max
      this.scale = Math.max(0.5, Math.min(4, this.scale))
    }
  }
}
</script>

<style lang="scss">
.modal-h-full {
  left: 50%;
  transform: translate(-50%);
}

#request-image-upload {
  .upload .upload-draggable {
    border: none 0;
    border-radius: 0;
    width: 100%;
    padding: 0;
  }

  .modal-close,
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    margin: 5px 5px 0 0;
  }
}

.doc-viewer {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 130%;
  min-height: 40vh;
  max-height: calc(100vh - 320px);
  border: 1px solid #dbdbdb;

  &.zoom-active {
    cursor: grab;
  }
}

.doc-controls {
  margin-top: -1em;
}
</style>

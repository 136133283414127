import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  toggle: false,
  apNotes: [],
  notesList: [],
  selectedApInvoiceNote: {},
  apInvoiceID: 0,
  fromShowNotes: false,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  pagination: {
    perPage: 10
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'author',
      label: 'Author',
      aria: 'Author',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'note',
      label: 'Note',
      aria: 'Note',
      width: '75%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      date: true,
      width: '5%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})

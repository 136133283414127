import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import Button from '@/components/buttons/Button'
import { accountsPayable } from '@/services/Invoices/store'

export const methods = {
  confirmDeleteInvoiceAttachment(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting A/P Invoice Attachment',
      message: `Are you sure you want to <b>delete</b> the invoice attachment for ${name}?`,
      confirmText: 'Delete A/P Invoice Attachment',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteInvoiceAttachment(id)
    })
  },

  determineAttachmentRows() {
    const dateFormat = 'LL'

    if (this.attachmentList) {
      this.apAttachments = this.attachmentList.map(entry => {
        const tempDate = _get(entry, 'createdDate', '')
        const documentId = _get(entry, 'documentID', '')
        const name = _get(entry, 'name', '')
        const createdDate = parseDate(tempDate, dateFormat)

        const myAttachmentId = _get(entry, 'apInvoiceAttachmentID', '')

        return {
          documentId: documentId,
          name: name,
          createdDate: createdDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processAttachmentUpdate(entry)
              },
              text: 'View'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteInvoiceAttachment(myAttachmentId, name)
              },
              text: 'Delete'
            }
          }
        }
      })
    }
  },

  async loadAttachments() {
    if (this.isDebug == true) console.debug('in loadAttachments()...')

    const payload = {
      apInvoiceID: this.apInvoiceID
    }

    await accountsPayable
      .dispatch('getApInvoiceById', {
        payload: payload
      })
      .then(({ invoice }) => {
        if (invoice) {
          this.attachmentList = invoice.apInvoiceAttachments
          this.determineAttachmentRows()
        }
      })
  },

  async deleteInvoiceAttachment(apInvoiceAttachmentID) {
    this.$store.dispatch('accounting/deleteInvoiceAttachment', {
      apInvoiceAttachmentID,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  },

  processAttachmentUpdate(attachment) {
    this.toggle = true
    this.selectedApInvoiceAttachment = attachment
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  createAttachmentModal() {
    this.selectedApInvoiceAttachment = null
    this.toggle = true
  },

  async reload() {
    this.loading = true

    this.rows = []

    await this.loadAttachments()

    this.loading = false
  }
}

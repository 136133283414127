<template>
  <PageContentLayoutOnly>
    <ModernTable
      :filters="filters"
      :rows="apNotes"
      :columns="columns"
      :pagination="pagination"
      readMoreColumn="Note"
    >
      <template v-slot:header>
        <div class="pb-5">
          <b-button @click="createNoteModal()" size="is-small" type="is-primary" rounded>
            Create Note
          </b-button>
          <b-button
            type="is-ghost"
            @click="toggleFilters"
            :style="styles.filterButton"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <InvoiceNoteModal
        :apInvoiceID="parseInt(apInvoiceID)"
        :apInvoiceNote="selectedApInvoiceNote"
        :fromShowNotes="fromShowNotes"
      />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import Modal from '@/components/Modal'
import InvoiceNoteModal from '@/components/InvoiceNoteModal'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'InvoiceNotes',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    Modal,
    InvoiceNoteModal
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },
    async toggle() {
      if (this.toggle == false) {
        await this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  async mounted() {
    this.apInvoiceID = this.$route.params.id || 0

    if ((this.$route.query.shownotes || '') === 'true') {
      this.fromShowNotes = true
      this.toggle = true
    } else {
      this.fromShowNotes = false
    }

    await this.reload()
  },

  i18n: {
    messages: {
      en: { invoiceNotes: { title: 'Notes' } }
    }
  }
}
</script>

<style></style>

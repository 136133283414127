import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import Button from '@/components/buttons/Button'
import { accountsPayable } from '@/services/Invoices/store'

export const methods = {
  confirmDeleteInvoiceNote(id, note) {
    this.$buefy.dialog.confirm({
      title: 'Deleting A/P Invoice Note',
      message: `Are you sure you want to <b>delete</b> the invoice note for ${note}?`,
      confirmText: 'Delete A/P Invoice Note',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteInvoiceNote(id)
    })
  },

  determineNoteRows() {
    const dateFormat = 'LL'

    if (this.notesList) {
      this.apNotes = this.notesList.map(entry => {
        const tempDate = _get(entry, 'createdDate', '')
        const note = _get(entry, 'note', '')
        const myNoteId = _get(entry, 'apInvoiceNoteID', '')
        const createdDate = parseDate(tempDate, dateFormat)
        const aspNetName = _get(entry, 'aspNetName', '')
        const isAuthenticated = _get(entry, 'isAuthenticated', false)

        return {
          author: aspNetName,
          note: note,
          createdDate: createdDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processNoteUpdate(entry)
              },
              text: 'Edit',
              disabled: !isAuthenticated,
              title: !isAuthenticated
                ? 'You do not have permission to change another person`s notes'
                : 'Edit'
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteInvoiceNote(myNoteId, note)
              },
              text: 'Delete',
              disabled: !isAuthenticated,
              title: !isAuthenticated
                ? 'You do not have permission to delete another person`s notes'
                : 'Delete'
            }
          }
        }
      })
    }
  },

  async loadNotes() {
    if (this.isDebug == true) console.debug('in loadNotes()...')

    const payload = {
      apInvoiceID: this.apInvoiceID
    }

    await accountsPayable
      .dispatch('getApInvoiceById', {
        payload: payload
      })
      .then(({ invoice }) => {
        if (invoice) {
          this.notesList = invoice.apInvoiceNotes
          this.determineNoteRows()
        }
      })
  },

  async deleteInvoiceNote(apInvoiceNoteID) {
    this.$store.dispatch('accounting/deleteInvoiceNote', {
      apInvoiceNoteID,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  },

  processNoteUpdate(note) {
    this.toggle = true
    this.selectedApInvoiceNote = note
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  createNoteModal() {
    this.selectedApInvoiceNote = null
    this.toggle = true
  },

  async reload() {
    this.loading = true

    this.rows = []

    await this.loadNotes()

    this.loading = false
  }
}

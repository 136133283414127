<template>
  <PageContentLayoutOnly invoice-conversation-messages role="region" new-conversation>
    <ListApInvoiceConversations :apInvoiceID="parseInt(apInvoiceID)" />
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ListApInvoiceConversations from '@/pages/Shared/communications/Global/List'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  components: {
    ListApInvoiceConversations,
    PageContentLayoutOnly
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit
    })
  },

  data,
  methods,

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { conversations: { title: 'A/P Invoice Messages' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>

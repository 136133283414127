<template>
  <PageContentLayoutOnly role="region" accountspayable>
    <div class="level pl-5 pr-5">
      <div class="pt-5 level-left level-item title">{{ $t('invoiceDetail.detailsTitle') }}</div>
    </div>

    <LinksMenu aria-label="invoice details tabs" :tabs="tabs" prefix="invoice." :styles="{}">
      <section :style="{ overflowX: 'auto', flexGrow: 2 }">
        <SlideTransition :name="transitionName">
          <keep-alive>
            <router-view />
          </keep-alive>
        </SlideTransition>
      </section>
    </LinksMenu>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'

import SlideTransition from '@/components/transitions/Slide.vue'
import LinksMenu from '@/components/menus/Links'

import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'

import { data } from './keys/data'
import { watch } from './keys/watch'

export default {
  components: {
    LinksMenu,
    SlideTransition,
    PageContentLayoutOnly
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch,

  mounted() {},

  i18n: {
    messages: {
      en: { invoiceDetail: { detailsTitle: 'Invoice Detail' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.accountspayable {
  .owner-avatar {
    margin: 1em auto 2em;
  }

  .card {
    box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

    .card {
      border: 0 !important;
    }
  }

  .avatar-img {
    border: 1px solid #e7e8f2;
    padding: 0.25em;
  }

  .address-card {
    h5,
    h6 {
      margin: 0;
    }
  }

  .details-img {
    img {
      border-radius: 0.5em;
    }
  }
}
</style>

<template>
  <div>
    <div class="is-size-4" v-if="buttonText == 'Update'">Edit Invoice Charge</div>
    <div class="is-size-4" v-else>Create Invoice Charge</div>

    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div :style="{ marginBottom: '12px' }">
              <valid-input
                :style="{ width: '50px' }"
                name="Amount"
                label="Amount"
                aria-label="Amount"
                type="text"
                vid="Amount"
                placeholder="Amount"
                spellcheck="true"
                required="true"
                rules="required"
                class="is-small"
                v-model="formData.Amount"
              />
            </div>

            <div aria-label="Select G/L Expense" :style="{ marginBottom: '12px' }">
              <label class="label">Select G/L Expense</label>
              <b-autocomplete
                size="is-small"
                field="label"
                icon="magnify"
                :loading="isFetching"
                placeholder="e.g. G/L Expense"
                :data="data"
                :open-on-focus="openOnFocus"
                v-model="glExpenseFilterQuery"
                required="true"
                @typing="getAsyncData"
                @select="option => (selected = option)"
              >
              </b-autocomplete>
            </div>

            <valid-input
              name="Description"
              aria-label="Description"
              label="Description"
              vid="Description"
              placeholder="Description"
              type="textarea"
              v-model="formData.Description"
              maxlength="255"
              rules="max:255|required"
              spellcheck="true"
              :style="{ marginBottom: '12px', width: '50px' }"
              class="is-small"
            />

            <div class="pb-5">
              <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                {{ buttonText }}
              </button>
            </div>
            <div class="pb-6"></div>
            <div class="pb-6"></div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
// Components
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationError } from '@/services/errors'
import { ToastProgrammatic as Toast } from 'buefy'
import kms from '@/services/kms'
import { mapState, mapGetters } from 'vuex' //mapGetters
import $ from 'jquery'
import debounce from 'lodash/debounce'
import { isNumeric } from '@/utilities/validate/isNumeric'

// components
import ValidInput from '@/components/inputs/ValidInput'

import moment from 'moment'
//const today = moment()

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

let DEFAULT_FORM_VALUES = {
  apInvoiceID: 0,
  amount: 0,
  glExpenseAccountId: 0,
  description: ''
}

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'InvoiceChargeModal',

  props: {
    apInvoiceID: Number,
    apInvoiceCharge: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,
      buttonText: '',
      openOnFocus: true,
      glExpenseFilterQuery: null,
      isFetching: false,
      data: [],
      showWeekNumber: false,
      newApInvoiceId: 0,
      activeStep: 'create'
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('accounting', ['glExpenseDropDownList']),
    glExpenseFilterSuggestions() {
      if (
        this.glExpenseFilterQuery &&
        this.glExpenseDropDownList &&
        this.glExpenseDropDownList != undefined
      ) {
        return this.glExpenseDropDownList.filter(
          a => a.label.indexOf(this.glExpenseFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.glExpenseDropDownList
      }
    }
  },

  mounted() {
    this.loading = true
    this.$store.dispatch('accounting/getGlExpenseList').then(() => {
      if (this.glExpenseDropDownList && this.glExpenseDropDownList != undefined) {
        if (this.isDebug == true)
          console.debug('glExpenseDropDownList=' + JSON.stringify(this.glExpenseDropDownList))

        this.data = this.glExpenseDropDownList

        if (
          this.apInvoiceCharge &&
          this.apInvoiceCharge.apInvoiceLineItemID != undefined &&
          this.apInvoiceCharge.apInvoiceLineItemID &&
          this.apInvoiceCharge.apInvoiceLineItemID > 0
        ) {
          console.log('this.apInvoiceCharge=' + JSON.stringify(this.apInvoiceCharge))
          this.formData.Amount = this.apInvoiceCharge.amount
          this.formData.Description = this.apInvoiceCharge.description
          this.glExpenseFilterQuery = this.apInvoiceCharge.glExpenseAccount
            ? this.apInvoiceCharge.glExpenseAccount.accountNumber +
              '-' +
              this.apInvoiceCharge.glExpenseAccount.subAccountNumber.toString().padStart(3, '0') +
              ' - ' +
              this.apInvoiceCharge.glExpenseAccount.description
            : ''
          this.buttonText = 'Update'
        } else {
          this.formData = { ...DEFAULT_FORM_VALUES }
          this.$refs.form.reset()
          this.buttonText = 'Add'
        }
      }
    })

    this.loading = false
  },

  methods: {
    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const glExpenseId = this.glExpenseDropDownList.filter(i =>
        i.label.includes(this.glExpenseFilterQuery)
      )

      const payload = {
        apInvoiceID: this.apInvoiceID,
        glExpenseAccountId: glExpenseId[0] ? glExpenseId[0].value : 0,
        hoaID: this.hoaId,
        amount:
          this.formData.Amount && this.formData.Amount != undefined ? this.formData.Amount : 0,
        description:
          this.formData.Description && this.formData.Description != undefined
            ? this.formData.Description
            : ''
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },
    async onFormSubmit() {
      if (
        !this.formData ||
        (this.formData == undefined && this.apInvoiceID && this.apInvoiceID > 0)
      ) {
        notifyError('There was a problem submitting your new invoice charge.')
        return
      }

      if (isNumeric({ n: this.formData.Amount }) == false) {
        notifyError('Please enter only numbers for the line item amount.')
        return
      }

      try {
        if (this.buttonText == 'Update') {
          //Update
          const updatePayload = this.getPayload()
          this.$store.dispatch('accounting/updateInvoiceCharge', {
            apInvoiceLineItemID: this.apInvoiceCharge.apInvoiceLineItemID,
            apInvoiceID: this.apInvoiceID,
            glExpenseAccountId: updatePayload.glExpenseAccountId,
            amount: updatePayload.amount,
            description: updatePayload.description,
            done: () => {
              // auto-close modal
              this.$emit('update:invoiceChargeToggle', (this.invoiceChargeToggle = false))
              $('#clickclose').click()
              this.loading = false
            }
          })
        } else {
          //Add
          const path = `/AccountsPayable/APInvoiceLineItem`

          const payload = this.getPayload()
          let validationMessages = ''

          if (payload.glExpenseAccountID == 0) {
            validationMessages = '<div>Please select a valid G/L Expense Account.</div>'
          }

          if (validationMessages != '') {
            notifyError(validationMessages)
            return
          }

          this.loading = true
          let results = await kms.post(path, payload)

          if (this.isDebug == true) console.debug(JSON.stringify(results))

          if (results && results.apInvoiceLineItemID >= 0) {
            notifyMessage(`The invoice charge was successfully created.`)

            // auto-close modal
            this.$emit('update:invoiceChargeToggle', (this.invoiceChargeToggle = false))
            $('#clickclose').click()
          } else {
            notifyError('There was a problem creating the invoice charge.')
          }
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem saving the invoice charge.' + e)
      }
      this.loading = false
    },

    getAsyncData: debounce(function(name) {
      // String update
      if (this.name !== name) {
        this.name = name
        this.data = []
      }

      // String cleared
      if (!name.length) {
        this.data = []
        return
      }

      this.isFetching = true
      if (this.isDebug == true) console.debug('this.name=' + this.name)

      if (this.glExpenseDropDownList) {
        this.data = this.glExpenseDropDownList.filter(i =>
          i.label.toLowerCase().includes(this.name.toLowerCase())
        )
      }
      this.isFetching = false
    }, 500),
    getMoreAsyncData: debounce(function() {
      this.getAsyncData(this.name)
    }, 250)
  }
}
</script>

<style lang="scss">
.step-details {
  background-color: #fff !important;
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

.b-steps.is-small .steps {
  margin-top: 1rem;
}

.b-steps .steps + .step-content {
  padding-top: 0 !important;
}

.step-navigation {
  display: flex;
  justify-content: flex-end;
}

.my-grid-class {
  height: 400px;
}

tbody {
  min-height: 100px !important;
  overflow: auto !important;
}
</style>

export const data = function() {
  return {
    isDebug: true,
    loading: false,
    tabs: [
      { name: 'invoicedetail', label: 'Invoice Detail' },
      { name: 'invoicecharges', label: 'Invoice Charges' },
      { name: 'invoiceapprovals', label: 'Approvals' },
      { name: 'invoicenotes', label: 'Notes' },
      { name: 'invoiceattachments', label: 'Attachments' },
      { name: 'invoicemessages', label: 'Messages' }
    ],

    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    transitionName: 'slide-down'
  }
}

import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  toggle: false,
  apAttachments: [],
  attachmentList: [],
  selectedApInvoiceAttachment: {},
  apInvoiceAttachmentID: 0,
  apInvoiceID: 0,
  isPostedAlready: false,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  pagination: {
    perPage: 10
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'documentId',
      label: 'Document Id',
      aria: 'Document Id',
      width: '20%',
      sortable: true,
      searchable: true
    },
    {
      field: 'documentName',
      label: 'Document Name',
      aria: 'Document Name',
      width: '60%',
      sortable: true,
      searchable: true
    },
    {
      field: 'createdDate',
      label: 'Created',
      date: true,
      width: '20%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})

import _get from 'lodash/get'

export const methods = {
  async reload() {
    this.loading = true

    this.apInvoiceID = _get(this.$route.params, 'id', 0)

    if (this.isDebug === true) console.debug('apInvoiceID=' + this.apInvoiceID)

    this.loading = false
  }
}

<template>
  <div id="invoice-note-modal">
    <div class="is-size-4" v-if="buttonText == 'Update'">Update Invoice Note</div>
    <div class="is-size-4" v-else>Create Invoice Note</div>

    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
          <fieldset>
            <div v-if="formData.Author && formData.Author != undefined">
              <valid-input
                style="width:50px !important;"
                name="Author"
                label="Author"
                disabled="true"
                type="text"
                vid="Author"
                placeholder="Author"
                spellcheck="true"
                aria-label="Author"
                class="is-small"
                v-model="formData.Author"
              />
            </div>

            <div class="pt-5">
              <valid-input
                style="width:50px !important;"
                name="Note"
                label="Note"
                maxlength="8000"
                type="textarea"
                vid="Note"
                placeholder="Note"
                spellcheck="true"
                aria-label="Note"
                rules="max:8000|required"
                class="is-small"
                v-model="formData.Note"
              />
            </div>
            <div class="pb-5">
              <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                {{ buttonText }}
              </button>
            </div>
            <div class="pb-6"></div>
            <div class="pb-6"></div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationError } from '@/services/errors'
import { notifyError } from '@/services/notify'
import ValidInput from '@/components/inputs/ValidInput'
import { mapState } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import debounce from 'lodash/debounce'
import moment from 'moment'
import $ from 'jquery'

let DEFAULT_FORM_VALUES = Object.freeze({
  apInvoiceID: 0,
  author: '',
  note: ''
})

export const defaultDateFormat = 'MM/dd/yyyy'

export default {
  name: 'InvoiceNoteModal',

  props: {
    apInvoiceID: Number,
    apInvoiceNote: Object,
    fromShowNotes: Boolean
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: false,
      formatDateFn: function(value) {
        return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
      },
      isDebug: true,
      selectedRow: null,
      buttonText: 'Add'
    }
  },

  created() {},

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  mounted() {
    this.loading = true
    if (
      this.apInvoiceNote &&
      this.apInvoiceNote.apInvoiceNoteID != undefined &&
      this.apInvoiceNote.apInvoiceNoteID &&
      this.apInvoiceNote.apInvoiceNoteID > 0
    ) {
      if (this.isDebug == true)
        console.debug('this.apInvoiceNote=' + JSON.stringify(this.apInvoiceNote))
      this.formData.Author = this.apInvoiceNote.aspNetName
      this.formData.Note = this.apInvoiceNote.note
      this.buttonText = 'Update'
    } else {
      this.formData = { ...DEFAULT_FORM_VALUES }
      this.$refs.form.reset()
      this.buttonText = 'Add'
    }

    this.loading = false
  },

  methods: {
    getPayload() {
      if (this.isDebug == true) console.debug('this.formData=' + JSON.stringify(this.formData))

      const payload = {
        apInvoiceID: this.apInvoiceID,
        note: this.formData.Note && this.formData.Note != undefined ? this.formData.Note : '',
        author:
          this.formData.Author && this.formData.Author != undefined ? this.formData.Author : ''
      }

      if (this.isDebug == true) console.debug('payload=' + JSON.stringify(payload))

      return payload
    },
    async onFormSubmit() {
      if (
        !this.formData ||
        (this.formData == undefined && this.apInvoiceID && this.apInvoiceID > 0)
      ) {
        notifyError('There was a problem submitting your new invoice note.')
        return
      }

      try {
        if (this.buttonText == 'Update') {
          const updatePayload = this.getPayload()
          this.$store.dispatch('accounting/updateInvoiceNote', {
            apInvoiceNoteID: this.apInvoiceNote.apInvoiceNoteID,
            apInvoiceID: this.apInvoiceID,
            note: updatePayload.note,
            done: () => {
              // auto-close modal
              this.$emit('update:toggle', (this.toggle = false))
              $('#clickclose').click()
              this.loading = false
            }
          })
        } else {
          //Add
          const payload = this.getPayload()
          let validationMessages = ''

          if (payload.note == '') {
            validationMessages = '<div>Please enter a note.</div>'
          }

          if (validationMessages != '') {
            notifyError(validationMessages)
            return
          }

          this.loading = true
          this.$store.dispatch('accounting/addNote', {
            apInvoiceID: this.apInvoiceID,
            note: this.formData.Note,
            done: () => {
              if (this.fromShowNotes == true) {
                this.$router.push({
                  path: '/ap-invoicespendingapproval/approval'
                })
              } else {
                // auto-close modal
                this.$emit('update:invoiceChargeToggle', (this.invoiceChargeToggle = false))
                $('#clickclose').click()
                this.loading = false
              }
            }
          })
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError('There was a problem saving the invoice note.' + e)
      }
      this.loading = false
    },

    getAsyncData: debounce(function(name) {
      // String update
      if (this.name !== name) {
        this.name = name
        this.data = []
      }

      // String cleared
      if (!name.length) {
        this.data = []
        return
      }

      this.isFetching = true
      if (this.isDebug == true) console.debug('this.name=' + this.name)

      if (this.glExpenseDropDownList) {
        this.data = this.glExpenseDropDownList.filter(i =>
          i.label.toLowerCase().includes(this.name.toLowerCase())
        )
      }
      this.isFetching = false
    }, 500),
    getMoreAsyncData: debounce(function() {
      this.getAsyncData(this.name)
    }, 250)
  }
}
</script>

<style lang="scss">
#invoice-note-modal {
  .step-details {
    background-color: #fff !important;
    padding-left: 0.25rem !important;
    padding-right: 0.5rem !important;
  }

  .b-steps.is-small .steps {
    margin-top: 1rem;
  }

  .b-steps .steps + .step-content {
    padding-top: 0 !important;
  }

  .step-navigation {
    display: flex;
    justify-content: flex-end;
  }

  .my-grid-class {
    height: 400px;
  }

  tbody {
    min-height: 100px !important;
    overflow: auto !important;
  }
}
</style>

<template>
  <PageContentLayoutOnly>
    <ModernTable :filters="filters" :rows="apApprovers" :columns="columns" :pagination="pagination">
      <template v-slot:header>
        <b-button
          type="is-ghost"
          @click="toggleFilters"
          :style="styles.filterButton"
          :class="'filter-button ' + (filters.show ? 'active' : '')"
        >
          <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
          </b-icon>
          <div style="display: inline-block">Filter</div>
        </b-button>
      </template>
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'InvoiceCharges',
  components: {
    PageContentLayoutOnly,
    ModernTable
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {
    hoaId() {
      this.reload()
    },
    codingCompleteDate() {
      this.reload()
    }
  },

  async mounted() {
    this.apInvoiceID = this.$route.params.id || 0
    await this.reload()
  },

  i18n: {
    messages: {
      en: { invoiceApprovals: { title: 'Approvals' } }
    }
  }
}
</script>

<style></style>

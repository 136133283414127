import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import Button from '@/components/buttons/Button'
import { accountsPayable } from '@/services/Invoices/store'

import moment from 'moment'
const today = moment()

export const methods = {
  confirmDeleteInvoiceCharge(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting A/P Invoice Charge',
      message: `Are you sure you want to <b>delete</b> the invoice charge for ${name}?`,
      confirmText: 'Delete A/P Invoice Charge',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteInvoiceCharge(id)
    })
  },

  determineChargeRows() {
    if (this.chargeList) {
      var dateFormat = 'LL'

      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      this.apLineItems = this.chargeList.map(i => {
        const tempDate = _get(i, 'createdDate', '')
        const createdDate = parseDate(tempDate, dateFormat)

        var accountNumber = _get(i, 'glExpenseAccount.accountNumber', '')
        var subAccountNumber = _get(i, 'glExpenseAccount.subAccountNumber', '')
        var glExpenseAccountFull =
          accountNumber +
          '-' +
          subAccountNumber.toString().padStart(3, '0') +
          ' - ' +
          _get(i, 'glExpenseAccount.description', '')

        var myApInvoiceLineItemId = _get(i, 'apInvoiceLineItemID', '')

        return {
          amount: formatter.format(_get(i, 'amount', 0)),
          glExpenseAccount: glExpenseAccountFull,
          description: _get(i, 'description', ''),
          createdDate: createdDate,
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processChargeUpdate(i)
              },
              text: 'Edit',
              visible: !this.isPostedAlready
            }
          },
          erase: {
            component: Button,
            props: {
              onClick: () => {
                this.confirmDeleteInvoiceCharge(myApInvoiceLineItemId, glExpenseAccountFull)
              },
              text: 'Delete',
              visible: !this.isPostedAlready
            }
          }
        }
      })
    }
  },

  async updateCodingCompleteDate(isRecode) {
    this.loading = true
    if (this.apInvoice) {
      const payload = {
        apInvoiceID: this.apInvoice.apInvoiceID,
        codingCompleteDate: isRecode === false ? moment(today).format('MM/DD/YYYY') : null
      }

      await accountsPayable.dispatch('updateCodingCompleteDate', {
        payload: payload,
        done: () => {
          this.loading = false
          if (isRecode === false) {
            this.hideCreateButton = true
          } else {
            this.hideCreateButton = false
          }

          if (this.apInvoice.apInvoiceID > 0) {
            this.reRender()
          }
        }
      })
    }

    this.loading = false
  },

  reRender() {
    //Necessary for now to force other tabs to re-render
    this.$forceUpdate()
    this.$router.go(0)
  },

  async loadCharges() {
    if (this.isDebug == true) console.debug('in loadCharges()...')

    const payload = {
      apInvoiceID: this.apInvoiceID
    }

    await accountsPayable
      .dispatch('getApInvoiceById', {
        payload: payload
      })
      .then(({ invoice }) => {
        if (invoice) {
          this.hideCreateButton =
            invoice.codingCompletedDate !== undefined && invoice.codingCompletedDate ? true : false
          this.apInvoice = invoice
          this.chargeList = invoice.apInvoiceLineItems
          this.determineChargeRows()
        }
      })
  },

  async deleteInvoiceCharge(apInvoiceLineItemID) {
    this.$store.dispatch('accounting/deleteInvoiceCharge', {
      apInvoiceLineItemID,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  },

  processChargeUpdate(lineItem) {
    this.toggle = true
    this.selectedApInvoiceCharge = lineItem
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  createLineItemModal() {
    this.selectedApInvoiceCharge = null
    this.toggle = true
  },

  async reload() {
    this.loading = true

    this.rows = []

    await this.loadCharges()

    this.loading = false
  }
}

import { parseDate } from '@/utilities/Date/parse'
import { notifyError, notifyMessage } from '@/services/notify'
import { accountsPayable } from '@/services/Invoices/store'
import Button from '@/components/buttons/Button'
import _get from 'lodash/get'

export const methods = {
  confirmApproveInvoiceApprover(approver) {
    this.$buefy.dialog.confirm({
      title: 'Approving A/P Invoice Approval',
      message: `Are you sure you want to <b>approve</b> the invoice for: ${approver.accountManagingUserName}?`,
      confirmText: 'Approve A/P Invoice Approval',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.processApproverUpdate(approver)
    })
  },

  confirmRemoveInvoiceApprover(approver) {
    this.$buefy.dialog.confirm({
      title: 'Removing A/P Invoice Approval',
      message: `Are you sure you want to <b>remove</b> the approval for: ${approver.accountManagingUserName}?`,
      confirmText: 'Remove A/P Invoice Approval',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.processApproverRemoveUpdate(approver)
    })
  },

  determineApproverRows(invoice) {
    const dateFormat = 'LL'

    if (this.approverList) {
      console.debug('approverList=' + JSON.stringify(this.approverList))

      this.apApprovers = this.approverList
        .filter(f => f.accountManagingUserName != null && f.accountManagingUserName.length > 0)
        .map(entry => {
          const approver = _get(entry, 'accountManagingUserName', '')
          const isRequired = _get(entry, 'isApprovalRequired', false)
          const tempDate = _get(entry, 'createdDate', '')
          const status = _get(entry, 'status', 'Not Approved')
          const createdDate = parseDate(tempDate, dateFormat)

          const approvalDate = _get(entry, 'approvalDate', '')
          let isApproved = approvalDate != '' && approvalDate != null ? true : false

          let isAuthenticated = _get(entry, 'isAuthenticated', false)
          if (isAuthenticated == null) {
            isAuthenticated = false
          }

          this.codingCompleteDate = _get(invoice, 'codingCompletedDate', '')
          console.debug('codingCompletedDate=' + this.codingCompleteDate)

          let toolTipMessage = ''
          if (!isAuthenticated) {
            toolTipMessage = 'You do not have access to approve.'
          }

          if (!this.codingCompleteDate || this.codingCompleteDate === '') {
            toolTipMessage = `${toolTipMessage} Line item charges must be entered and marked as completed.`
          }

          return {
            approver: approver,
            required: isRequired,
            status: status,
            createdDate: createdDate,
            edit: {
              component: Button,
              props: {
                onClick: () => {
                  this.confirmApproveInvoiceApprover(entry)
                },
                text: 'Approve',
                visible: !this.isPostedAlready,
                disabled: !isAuthenticated || !this.codingCompleteDate,
                tooltip: !isAuthenticated || !this.codingCompleteDate ? toolTipMessage : ''
              }
            },
            erase: {
              component: Button,
              props: {
                onClick: () => {
                  this.confirmRemoveInvoiceApprover(entry)
                },
                text: 'Remove',
                visible: !this.isPostedAlready,
                disabled: !isApproved,
                tooltip: !isApproved ? 'You do not have access to remove this approval.' : ''
              }
            }
          }
        })
    }
  },

  async loadApprovers() {
    if (this.isDebug == true) console.debug('in loadApprovers()...')

    const payload = {
      apInvoiceID: this.apInvoiceID
    }

    await accountsPayable
      .dispatch('getApInvoiceById', {
        payload: payload
      })
      .then(({ invoice }) => {
        if (invoice) {
          this.approverList = invoice.apInvoiceApproval.apInvoiceApprovalApprovers || []
          this.apInvoiceStatus = _get(invoice.apInvoiceApproval.apInvoiceStatus, 'status', '')
          this.determineApproverRows(invoice)
        }
      })
  },

  async deleteInvoiceApprover(apInvoiceApproverID) {
    this.$store.dispatch('accounting/deleteInvoiceApprover', {
      apInvoiceApproverID,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  },

  processApproverUpdate(approver) {
    if (!approver || approver == undefined) {
      notifyError('Could not approve the approval at this time.')
      return
    }

    const payload = {
      apInvoiceID: this.apInvoiceID
    }

    this.$store.dispatch('accounting/approveInvoiceApproval', {
      payload: payload,
      done: ({ details }) => {
        if (details) {
          notifyMessage(details.approvalMessage)
          this.loading = false
          this.reload()
        }
      }
    })
  },

  processApproverRemoveUpdate(approver) {
    if (!approver || approver == undefined) {
      notifyError('Could not approve the approval at this time.')
      return
    }

    approver.approvalDate = null
    approver.approvalOverrideByAspNetUserID = null
    approver.isApprovalRemoval = true

    this.$store.dispatch('accounting/updateInvoiceApproval', {
      payload: approver,
      done: () => {
        this.loading = false
        this.reload()
        this.isReadyForApproval = true
        this.isReadyForPost = false
      }
    })
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  createApproverModal() {},

  async reload() {
    this.loading = true

    this.rows = []

    await this.loadApprovers()

    this.loading = false
  }
}
